import React from "react";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import { PrismicRichText } from "@prismicio/react";

const serializer = {
  paragraph: ({ children }) => (
    <p className="text-sm md:text-base h-36 flex items-center justify-center text-center px-3.5 font-medium text-slate-200">
      {children}
    </p>
  )
};

const availableColors = [400, 500, 600, 700, 800, 900];
function getColor(index) {
  if (index < 0 || isNaN(index)) {
    return undefined;
  }

  if (index < availableColors.length) {
    return availableColors[index];
  }

  let effectiveIndex = index % (availableColors.length * 2);
  if (effectiveIndex >= availableColors.length) {
    effectiveIndex = availableColors.length * 2 - effectiveIndex - 1;
  }

  return availableColors[effectiveIndex];
}

function Boxes({ items }) {
  if (!items) {
    return null;
  }

  return (
    <div
      className={`grid grid-cols-2 md:grid-cols-${Math.min(
        items.length,
        8
      )} gap-4 mt-10`}
    >
      {items.map((item, i) => (
        <div
          className={twMerge(
            clsx("shadow-xl rounded-t-lg", `bg-cyan-${getColor(i)}`)
          )}
        >
          <PrismicRichText components={serializer} field={item.box_content} />
        </div>
      ))}
    </div>
  );
}

export default Boxes;

import { usePrismicDocumentByID } from "@prismicio/react";
import React, { useMemo } from "react";
import Slick from "react-slick";
import Slide from "./Slide";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Slider({ content }) {
  const [component] = usePrismicDocumentByID(content?.id, "slider");

  const sliderSettings = useMemo(() => {
    return {
      dots: component?.data?.dots || false,
      infinite: component?.data?.infinite || true,
      lazyLoad: component?.data?.lazy_load || true,
      autoplay: component?.data?.autoplay || true,
      speed: component?.data?.speed__ms_ || 500,
      slidesToShow: component?.data?.slides_to_show || 1,
      slidesToScroll: component?.data?.slides_to_scroll || 1,
      autoplaySpeed: component?.data?.autoplay_speed || 3000
    };
  }, [component]);

  return (
    <Slick {...sliderSettings}>
      {component?.data?.slides?.map((slide, i) => (
        <Slide key={i} index={i} slide={slide} />
      ))}
    </Slick>
  );
}

export default Slider;

import React from "react";

import RichText from "./RichText";
import Slider from "./Slider";
import Counter from "./Counter";
import Boxes from "./Boxes";
import Map from "./Map"

function ComponentRenderer({ sliceType, content, items, fullData }) {
  const componentMap = {
    rich_text: RichText,
    slider: Slider,
    counters: Counter,
    boxes: Boxes,
    map: Map
  };

  const Component = componentMap[content?.type] || componentMap[sliceType];
  if (!Component) {
    return null;
  }

  return <Component sliceType={sliceType} content={content} items={items} fullData={fullData} />;
}

export default ComponentRenderer;

import React from "react";
import Icon from "../Icon";
import CountUp from "react-countup";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

function CounterComponent({ number, title }, props) {
  return (
    <div className="b-number number h-36 md:h-auto flex flex-col border-solid border-2 border-myBlue-border-color px-4 md:px-10 py-10 font-bold rounded-lg">
      <CountUp
        start={0}
        separator=" "
        decimal=","
        duration={3}
        className="counter text-2xl text-slate-700"
        end={number}
      />
      <span className="text-sm">{title}</span>
    </div>
  );
}

function Counter({ items }) {
  if (!items) {
    return null;
  }

  return (
    <>
      <div className="chart mb-4">
        <div className="numbers flex flex-row justify-center text-center gap-x-4 items-center h-auto md:h-full">
          <div className="flex flex-row gap-4">
            {items.map((item, index) => (
              <div key={index} className="relative min-w-[200px] max-w-[300px]">
                <CounterComponent number={item.number} title={item.title1} />
                <span
                  className={twMerge(
                    clsx(
                      "absolute top-6 inset-x-0",
                      `text-${item.icon_color || "cyan-600"}`
                    )
                  )}
                >
                  {item.icon_name && (
                    <Icon
                      icon={item.icon_name}
                      className="inline-block w-[26px] h-[21px]"
                    />
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Counter;

import { PrismicRichText } from "@prismicio/react";
import React from "react";

const Slide = ({ index, slide }) => {
  return (
    <>
      <div className="relative">
        <img
          src={slide?.image?.url}
          alt="Slide"
          className="w-full h-72 lg:h-[40.625rem] object-cover"
        />
        {slide?.body1?.length > 0 && (
          <div
            className={`${
              index % 2 === 0 ? "left-6 md:left-20" : "right-6 md:right-20"
            } w-10/12 md:w-2/6 h-44 bg-white absolute inset-y-5 md:inset-y-1/3 p-5`}
          >
            <div className="border-2 border-mygBlue font-sans p-2 text-center h-full min-h-full md:flex md:justify-center md:flex-col">
              <PrismicRichText field={slide?.body1} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Slide;

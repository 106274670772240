import React from "react";
import Contact from "./Contact";
import Slogan from "./Slogan";
import { Link } from "react-router-dom";
import { useSinglePrismicDocument } from "@prismicio/react";

function TopHeader() {
  const [siteSettings] = useSinglePrismicDocument("site_settings");

  return (
    <div className="bg-mygBlue">
      <div className="xl:container p-2 border-slate-100 md:h-14">
        <div className="flex justify-between md:flex-row flex-col md:h-full items-center">
          <div className="slogan">
            <Slogan
              sloganClassName="text-white"
              text={siteSettings?.data?.slogan}
            />
          </div>
          <div className="flex justify-end flex-row pt-6">
            <Link
              to={siteSettings?.data?.contact_us_page_link?.url}
              target={siteSettings?.data?.contact_us_page_link?.target}
            >
              <Contact logos="../images/address.png" altText="Adress" />
            </Link>
            <Link
              to={siteSettings?.data?.telephone_link?.url}
              target={siteSettings?.data?.telephone_link?.target}
            >
              <Contact logos="../images/phone.png" altText="Telefon" />
            </Link>
            <Link
              to={siteSettings?.data?.linkedin_link?.url}
              target={siteSettings?.data?.linkedin_link?.target}
            >
              <Contact logos="../images/linkedin.png" altText="Linkedin" />
            </Link>
            <Link
              to={siteSettings?.data?.instagram_link?.url}
              target={siteSettings?.data?.instagram_link?.target}
            >
              <Contact logos="../images/instagram.png" altText="Instagram" />
            </Link>
            <Link
              to={siteSettings?.data?.whatsapp_link?.url}
              target={siteSettings?.data?.whatsapp_link?.target}
            >
              <Contact logos="../images/whatsapp.png" altText="WhatsApp Logo" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;

import "./App.css";
import { Routes, Route } from "react-router-dom";
import ContactUs from "./contactUs/ContactUs";
import TopHeader from "./header/contact/TopHeader";
import { Logo } from "./header/Logo";
import { Megamenu } from "./header/megamenu/Megamenu";
import Footer from "./footer/Footer";
import Page from "./Page";

function App() {
  return (
    <>
      <TopHeader />
      <header className="shadow-lg">
        <div className="xl:container  grid grid-cols-1 md:grid-cols-4 gap-4 items-center relative">
          <div className="md:col-span-1">
            <Logo />
          </div>
          <div className="md:col-span-3">
            <Megamenu />
          </div>
        </div>
      </header>

      <Routes>
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/*" element={<Page />} />
      </Routes>

      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;

import { usePrismicDocumentByUID } from "@prismicio/react";
import React from "react";
import { useLocation } from "react-router-dom";
import ComponentRenderer from "./components/ComponentRenderer";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import Icon from "./Icon";

function Page() {
  const location = useLocation();
  const [page, { state }] = usePrismicDocumentByUID(
    "page",
    location.pathname.slice(1).toLowerCase() || "homepage"
  );

  return (
    <>
      {state === 'loading' ? (
        <div className="flex items-center justify-center w-full h-[calc(100vh-500px)]">
          <Icon icon="FaSpinner" className="animate-spin w-8 h-8" />
        </div>
      ) : (
        <>
          {page?.data?.full_width_first_component && (
            <ComponentRenderer
              content={page?.data?.full_width_first_component}
            />
          )}

          <div className="container b-page">
            <div className="relative my-5 text-center md:text-left">
              <span className="font-medium text-base text-xl md:text-4xl">
                {page?.data?.title?.[0]?.text}
              </span>
            </div>

            <div className="grid grid-cols-12 gap-4">
              {page?.data?.body?.map((slice, index) => (
                <div
                  key={index}
                  className={twMerge(
                    clsx(
                      "col-span-12",
                      `md:col-span-${slice?.primary?.column_size || 12}`,
                      slice?.primary?.parent_custom_class || ""
                    )
                  )}
                >
                  <ComponentRenderer
                    sliceType={slice?.slice_type}
                    items={slice?.items}
                    content={slice?.primary?.content}
                    fullData={slice}
                  />
                </div>
              ))}
            </div>
          </div>

          {page?.data?.full_width_last_component && (
            <ComponentRenderer
              content={page?.data?.full_width_last_component}
            />
          )}
        </>
      )}
    </>
  );
}

export default Page;

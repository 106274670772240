import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSinglePrismicDocument } from "@prismicio/react";

export const Megamenu = () => {
  const [siteSettings] = useSinglePrismicDocument("site_settings");

  const [menuStates, setMenuStates] = useState({});
  const [show, setShow] = useState(false);

  return (
    <>
      <nav className="bg-white border-gray-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <button
            onClick={() => setShow(!show)}
            data-collapse-toggle="navbar-dropdown"
            type="button"
            className="absolute inset-3.5 inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-dropdown"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${
              show
                ? "block md:block w-full md:w-auto absolute md:static top-2/4 inset-x-0 z-10"
                : "b-navigation__hidden"
            }`}
            id="navbar-dropdown"
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              {siteSettings?.data?.header_body?.map((headerLink, i) => (
                <li
                  key={i}
                  onMouseLeave={() =>
                    setMenuStates({ ...menuStates, [i]: { open: false } })
                  }
                >
                  <Link
                    className="text-lg text-white"
                    to={headerLink.primary?.link?.url}
                    target={headerLink.primary?.link?.target}
                  >
                    <button
                      onMouseOver={() =>
                        setMenuStates({ ...menuStates, [i]: { open: true } })
                      }
                      className="flex items-center justify-between w-full py-2 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                    >
                      {headerLink.primary?.text}
                      {headerLink.items?.length > 0 && (
                        <svg
                          className="w-2.5 h-2.5 ml-2.5 shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      )}
                    </button>
                  </Link>

                  {headerLink.items?.length > 0 && (
                    <div
                      className={
                        menuStates?.[i]?.open
                          ? "absolute z-10 block font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-[calc(100%-2rem)] md:w-44 dark:bg-gray-700 dark:divide-gray-600"
                          : "hidden"
                      }
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-400"
                        aria-labelledby="dropdownLargeButton"
                      >
                        {headerLink.items.map((item, i) => (
                          <li
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            key={i}
                          >
                            <Link
                              className="text-lg"
                              to={item.sublink_link?.url}
                              target={item.sublink_link?.target}
                              onClick={() => {
                                setMenuStates({});
                                setShow(false);
                              }}
                            >
                              {item.sublink_text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

import React from "react";
import Maps from "./Maps";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import { useSinglePrismicDocument } from "@prismicio/react";

function ContactUs() {
  const [siteSettings] = useSinglePrismicDocument("site_settings");
  return (
    <div>
      <div className="container py-10">
        <div className="relative my-5 text-center md:text-left">
          <span className="font-medium text-base md:text-4xl">İletişim</span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border-slate-300 border text-center bg-slate-50 text-slate-800">
            <div className="bg-slate-100 border-b-slate-300 p-2">
              <span className="flex justify-center">
                <Icon
                  icon="FaMapMarkerAlt"
                  className="mb-4 w-[24px] h-[32px] text-[#8ad9e4]"
                />
              </span>
              <p className="mb-2 font-semibold">Adres</p>
            </div>
            <p className="text-slate-600 p-4">{siteSettings?.data?.adress}</p>
          </div>
          <div>
            <p className="mb-2 font-semibold text-neutral-600">İletişim</p>
            <div className="flex items-end">
              <span>
                <Icon
                  icon="FaPhone"
                  className="mb-4 w-[21px] h-[21px] text-[#525252]"
                />
              </span>
              <Link to={siteSettings?.data?.telephone_link?.url}>
                <p className="text-slate-600 p-4">
                  {siteSettings?.data?.contact_phone}
                </p>
              </Link>
            </div>

            <div className="flex items-end">
              <span>
                <Icon
                  icon="FaEnvelope"
                  className="mb-4 w-[21px] h-[21px] text-[#525252]"
                />
              </span>
              <Link to={"mailto:" + siteSettings?.data?.contact_email}>
                <p className="text-slate-600 p-4">
                  {siteSettings?.data?.contact_email}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        {siteSettings?.data?.location && <Maps markers={[{
          lat: siteSettings.data.location.latitude,
          lng: siteSettings.data.location.longitude
        }]}/>}
      </div>
    </div>
  );
}

export default ContactUs;

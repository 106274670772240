import React from 'react'

function Slogan(props) {
    const sloganDesc = props.text
    const sloganClass = props.sloganClassName
  return (
    <div className={sloganClass}>
        {sloganDesc}
    </div>
  )
}

export default Slogan
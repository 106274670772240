import { usePrismicDocumentByID } from "@prismicio/react";
import React, { useMemo } from "react";
import Maps from "../contactUs/Maps";

function Map({ content }) {
  const [component] = usePrismicDocumentByID(content?.id, "map");

  const markers = useMemo(() => {
    // Map the locations array to an array of lat-lng objects
    return component?.data?.markers.map(loc => ({
      lat: loc.location.latitude,
      lng: loc.location.longitude
    }));
  }, [component?.data?.markers]);

  const boundaries = useMemo(() => {
    return component?.data?.boundaries.map(boundary => ({
      lat: boundary?.boundary?.latitude,
      lng: boundary?.boundary?.longitude
    }));
  }, [component?.data?.boundaries]);

  return (
    <>
      {markers?.length && (
        <Maps
          className="testdeneme"
          zoom={4}
          markers={markers}
          boundaries={boundaries}
        />
      )}
    </>
  );
}

export default Map;

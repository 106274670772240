import { PrismicRichText } from "@prismicio/react";
import React, { useMemo } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const linkResolver = doc => "/" + doc.uid;

const serializer = {
  heading1: ({ children }) => <h1 className="text-4xl">{children}</h1>,
  heading2: ({ children }) => <h2 className="text-3xl">{children}</h2>,
  heading3: ({ children }) => <h3 className="text-2xl">{children}</h3>,
  heading4: ({ children }) => <h4 className="text-xl">{children}</h4>,
  heading5: ({ children }) => <h5 className="text-lg">{children}</h5>,
  heading6: ({ children }) => <h6 className="text-base">{children}</h6>,
  listItem: ({ children }) => (
    <li className="before:bg-cyan-900 font-sans flex items-center my-2 pl-4 min-h-40 relative b-colored-list">
      {children}
    </li>
  ),
  zoomable_image: ({ node }) => {
    const linkUrl = node.linkTo ? linkResolver(node.linkTo) : null;
    const wrapperClassList = [node.label || "", "block-img"];

    const img = (
      <Zoom>
        <img src={node.url} alt={node.alt ? node.alt : ""} />
        <div className="b-image__text">{node.alt ? node.alt : ""}</div>

      </Zoom>
    );

    return (
      <p className={wrapperClassList.join(" ")}>
        {linkUrl ? (
          <a target={node?.linkTo?.target} href={linkUrl}>
            {img}
          </a>
        ) : (
          img
        )}
      </p>
    );
  }
};

function RichText({ sliceType, content, fullData }) {
  const localComponent = useMemo(() => {
    if (sliceType === "rich_text") {
      const contentCopy = JSON.parse(JSON.stringify(content));
      if (contentCopy.length) {
        contentCopy.forEach((item, index) => {
          if (item.type === "image") {
            if (fullData?.primary?.allow_zooming_on_images === true) {
              contentCopy[index].type = "zoomable_image";
            }
          }
        });
      }
      return contentCopy;
    }

    return null;
  }, [sliceType, content, fullData?.primary?.allow_zooming_on_images]);

  if (!localComponent) {
    return null;
  }

  return (
    <>
      <PrismicRichText field={localComponent} components={serializer} />
    </>
  );
}

export default RichText;

import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "500px"
};

function Maps({ markers, boundaries }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBhWsx-10XvhBRt1COsRaMN8Qp8aWsGJ_c"
  });

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null);

  const zoomLevel = 14;

  const onLoad = React.useCallback(
    function callback(map) {
      const interval = setInterval(() => {
        if (markers && markers.length) {
          const bounds = boundaries
            ? new window.google.maps.LatLngBounds(...boundaries)
            : new window.google.maps.LatLngBounds(...markers);
          map.fitBounds(bounds);
          setMap(map);
          clearInterval(interval);
        }
      }, 100);
    },
    [markers]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={zoomLevel}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{ scrollwheel: true, zoomControl: true }}
        // mapTypeId="satellite"
      >
        {markers.map((marker, index) => {
          return (
            <>
              <div key={index}>
                <Marker position={marker} />
              </div>
            </>
          );
        })}
      </GoogleMap>
    )
  );
}

export default Maps;

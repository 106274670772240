import React from 'react'

function Contact(props) {
    const contactTitle = props.title;
    const contactLogos = props.logos;
    const contactImagesAltText = props.altText;
  return (
    <div className='ml-4'>
      <img src={contactLogos} alt={contactImagesAltText} className='w-5 invert' />
      <span className='px-2'>{contactTitle}</span>
    </div>
  )
}

export default Contact
import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../header/Logo";
import Slogan from "../header/contact/Slogan";
import { useSinglePrismicDocument } from "@prismicio/react";
import { PrismicRichText } from "@prismicio/react";

function Footer() {
  const [siteSettings] = useSinglePrismicDocument("site_settings");

  return (
    <div className="border-t-2 mt-4 text-center text-neutral-600 dark:bg-neutral-600 dark:text-neutral-200 lg:text-left">
      <div className="container py-10 text-center md:text-left">
        <div className="grid-1 grid gap-8 md:grid-cols-4">
          <div>
            <h6 className="mb-4 flex items-center   font-semibold uppercase  ">
              <Logo />
            </h6>
            <Slogan
              sloganClassName="text-current"
              text={siteSettings?.data?.slogan}
            />
          </div>
          {siteSettings?.data?.footer_body.map((footerLink, i) => (
            <div key={i}>
              <h6 className="w-full text-left block mb-4 font-semibold uppercase">
                {footerLink.primary?.title}
              </h6>
              {footerLink.items?.map((item, i) => (
                <p key={i} className="mb-4">
                  <Link
                    className="w-full text-left block text-neutral-600 dark:text-neutral-200"
                    to={item?.link?.url}
                    target={item?.link?.target}
                  >
                    {item?.link_text}
                  </Link>
                </p>
              ))}
            </div>
          ))}

          {/* <!--Contact section--> */}
          <div>
            <h6 className="mb-4 flex font-semibold uppercase">İletişim</h6>
            <p className="mb-4 flex items-center    ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-5 w-5 shrink-0"
              >
                <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
              </svg>
              {siteSettings?.data?.adress}
            </p>
            <p className="mb-4 flex items-center    ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-5 w-5 shrink-0"
              >
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>
              {siteSettings?.data?.contact_email}
            </p>
            <p className="mb-4 flex items-center    ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-5 w-5 shrink-0"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>
              {siteSettings?.data?.contact_phone}
            </p>
          </div>
        </div>
      </div>

      {/* <!--Copyright section--> */}
      <div className="bg-neutral-200 p-6 text-center dark:bg-neutral-700">
        {siteSettings?.data?.copyright_notice && (
          <PrismicRichText field={siteSettings?.data?.copyright_notice} />
        )}
      </div>
    </div>
  );
}

export default Footer;
